import React, { useState } from 'react';
import "../../css/home.css";
import "../../css/button.css";
import MonCarrousel from './MonCarrousel';
import MapComponent from './MapComponent';
import MonCarrousel2 from './MonCarrousel2';
// import { MapContainer, TileLayer, useMap,Marker,Popup } from 'leaflet'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Button } from "react-bootstrap";
import DemoCarousel from "./Carousel";
// import Demographie from "./DemographicData";
import Newsletter from "./Newsletter";
// import Maps from "./Maps";
// import MapsSante from "./MapsSante";
import Partenaires from './NosPartenaires';
import { useEffect } from 'react';
import Itableindicateur from "./Itableindicateur";
import Itableindicateur_2 from "./Itableindicateur_2";
import S_Itableindicateur from "./S_Itableindicateur";
import S_Itableindicateur_2 from "./S_Itableindicateur_2";
import Modal from "react-modal";

const fermerModal = require("../images/fermerModal.png");
const educicon = require("../images/educicon.png");
const santeicon = require("../images/educsante.png");
const tableicon = require("../images/itable.png");



export const Home = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('structure');

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const [isEducation, SetEducation] = React.useState(true);
  const [isSante, SetSanté] = React.useState(false);
  const HandleEducation = () => {
    SetEducation(true);
    SetSanté(false);
    /* console.log("test education"); */
  };
  const HandleSante = () => {
    SetSanté(true);
    SetEducation(false);
    /* console.log("test sante "); */
  };




  const [selectedOption, setSelectedOption] = useState('option1');

  
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);

  };


  const Access = () => {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyItems: "center",
        }}
      >
        <iframe
          title="DashboardIGT"
          width="100%"
          height="100%"
          src="https://app.powerbi.com/view?r=eyJrIjoiZWM0ZDhhODYtOGQzOS00ZTNjLWIwMzktMjBmMmYxMzQ4ZDlmIiwidCI6ImJmZTY0YzY0LWIwY2UtNDJiZC04MDlhLWE4ODlhMjJhZGQwOSJ9" 
         
          frameborder="0"
          allowFullScreen="true"
        ></iframe>
      </div>
    );
  };

  const Env = () => {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyItems: "center",
        }}
      >
        <iframe
          title="DashboardIGT"
          width="100%"
          height="100%"
          src="https://app.powerbi.com/view?r=eyJrIjoiNjUzMzJmZTEtNTNlOS00NTlhLWI5YjItYWZiYmE0OWUyNmI4IiwidCI6ImJmZTY0YzY0LWIwY2UtNDJiZC04MDlhLWE4ODlhMjJhZGQwOSJ9"
          frameborder="0"
          allowFullScreen="true"
        ></iframe>
      </div>
    );
  };

  const Performance = () => {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyItems: "center",
        }}
      >
        <iframe
          title="DashboardIGT"
          width="100%"
          height="100%"
          src="https://app.powerbi.com/view?r=eyJrIjoiYmIyYTQ1ZDUtZGU3Ny00ZmFhLTkyODYtYjAyOTNkYmNkNDQ3IiwidCI6ImJmZTY0YzY0LWIwY2UtNDJiZC04MDlhLWE4ODlhMjJhZGQwOSJ9"
         
          frameborder="0"
          allowFullScreen="true"
        ></iframe>
      </div>
    );
  };

  const Gouvernance = () => {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyItems: "center",
        }}
      >
        <iframe
          title="DashboardIGT"
          width="100%"
          height="100%"
          src="https://app.powerbi.com/view?r=eyJrIjoiNGYwZjEwMjQtNzVjMC00MGFmLWI2MTUtMTJjNjY5MGFlZTNmIiwidCI6ImJmZTY0YzY0LWIwY2UtNDJiZC04MDlhLWE4ODlhMjJhZGQwOSJ9"
         
          frameborder="0"
          allowFullScreen="true"
        ></iframe>
      </div>
    );
  };

  const CommuneDashbaord = () => {
    return (
      <>
      <div className='communeDashboard'>
        {/*Nouveau pour les autres sites montrolland et thiadiaye*/}
        <div className='communeDashboard1'>
          <h4 className='sous-titre'>Sous-domaine</h4>
          <div className='sous-communeDashboard1'>
            <label className='labelRadio'>
              <input
              className='inputRadio'
                type="radio"
                value="option1"
                checked={selectedOption === 'option1'}
                onChange={handleOptionChange}
              />
              ACCES ET PARTICIPATION
            </label >

            <label className='labelRadio'>
              <input
              className='inputRadio'
                type="radio"
                value="option2"
                checked={selectedOption === 'option2'}
                onChange={handleOptionChange}
              />
              ENVIRONNEMENT SCOLAIRE
            </label>

            <label className='labelRadio'>
              <input
              className='inputRadio'
                type="radio"
                value="option3"
                checked={selectedOption === 'option3'}
                onChange={handleOptionChange}
              />
              PERFORMANCE
            </label>

            <label className='labelRadio'>
              <input
              className='inputRadio'
                type="radio"
                value="option4"
                checked={selectedOption === 'option4'}
                onChange={handleOptionChange}
              />
              GOUVERNANCE
            </label>
          </div>

        </div>
        <div className='communeDashboard2'>
          {selectedOption == 'option1' && <Access/>}
          {selectedOption == 'option2' && <Env/>}
          {selectedOption == 'option3' && <Performance/>}
          {selectedOption == 'option4' && <Gouvernance/>}
        </div>
      </div>
    </>
    );
  };

  const TableauBordEducation = () => {
    const [isEducation_click, setIsEducation_click] = useState(true);
    const [selectedOption2, setSelectedOption2] = useState('Education');

  

    const handleOptionChange = (e) => {
      setSelectedOption2(e.target.value)
    
      if (e.target.value == "Sante") {
        setIsEducation_click(false)
      } else {
        setIsEducation_click(true)
      }
    };

     // Styles inline pour le bouton radio personnalisé
  const radioMarkStyle = {
    height: '16px',
    width: '16px',
    borderRadius: '50%',
    border: '2px solid #198754', // Vert pour le contour
    marginRight: '8px',
    position: 'relative',
  };

  const radioCheckedStyle = {
    backgroundColor: '#198754', // Vert lorsque sélectionné
    position: 'relative',
  };

  const radioCheckedInnerStyle = {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '8px',
    width: '8px',
    backgroundColor: 'white', // Le point intérieur blanc
    borderRadius: '50%',
  };
  
    return (
      <>
      <div style={{display:"flex", justifyContent:"center", gap:"8px", marginBottom:"10px"}} >
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
        <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', fontSize: '16px' }}>
          <input
            type="radio"
            value="Education"
            checked={selectedOption2 === 'Education'}
            onChange={handleOptionChange}
            style={{ display: 'none' }} // Cache le bouton radio natif
          />
          <span style={selectedOption2 === 'Education' ? { ...radioMarkStyle, ...radioCheckedStyle } : radioMarkStyle}>
            {selectedOption2 === 'Education' && <span style={radioCheckedInnerStyle}></span>}
          </span>
          Éducation
        </label>
      </div>

      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
        <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', fontSize: '16px' }}>
          <input
            type="radio"
            value="Sante"
            checked={selectedOption2 === 'Sante'}
            onChange={handleOptionChange}
            style={{ display: 'none' }} // Cache le bouton radio natif
          />
          <span style={selectedOption2 === 'Sante' ? { ...radioMarkStyle, ...radioCheckedStyle } : radioMarkStyle}>
            {selectedOption2 === 'Sante' && <span style={radioCheckedInnerStyle}></span>}
          </span>
          Santé
        </label>
      </div>
      </div>
      <div
        style={{
          width: "100%",
          height: "450px",
          display: "flex",
          justifyItems: "center",
          zIndex:'-1000',
          border:"5px solid #208001"
        }}
      >
        
  
         <MapComponent  isEducation = {isEducation_click}/>
      </div>
      </>
    );
  };
  const TableauBordSante = () => {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyItems: "center",
        }}
      >
        <iframe
          title="DashboardIGT"
          width="100%"
          height="100%"
          //SRC SANTE BOULEL TEMPORAIRE
          src="https://app.powerbi.com/view?r=eyJrIjoiZWVjMWQ0MzktNTZlNS00N2QyLWJhOWItNTYxYTkzYTIzNzMxIiwidCI6ImJmZTY0YzY0LWIwY2UtNDJiZC04MDlhLWE4ODlhMjJhZGQwOSJ9"
          
          frameborder="0"
          allowFullScreen="true"
        ></iframe>
      </div>
    );
  };
  return (
    <div>
      <div className="main">
        <div className="carouselcontain">
          <div className="cardDomain">
            <div>  
              <MonCarrousel />
            </div>
          </div>
          <div className="carouseldiv">
            <iframe title="homePageBoulel" style={{height:`100%`, width:`100%`}} 
              src="https://app.powerbi.com/view?r=eyJrIjoiOTQzZmJlOWQtN2YyNS00YzA3LTlmZjQtMzhjOWYyZmQ2MDI1IiwidCI6ImJmZTY0YzY0LWIwY2UtNDJiZC04MDlhLWE4ODlhMjJhZGQwOSJ9" 
              frameborder="0" allowFullScreen="true">
            </iframe> 
          </div>   
        </div>
        <div>
          <div className='contain'>
           <div className='sous-contain'><span className='span1'>Atteinte</span> <span className='span2' style={{backgroundColor:"#356C2A"}} ></span></div>
           <div className='sous-contain'><span className='span1'>En partie atteinte</span> <span className='span2' style={{backgroundColor:"#EEFF00"}} ></span></div>
           <div className='sous-contain'><span className='span1'>Non atteinte</span> <span className='span2' style={{backgroundColor:"#D70012"}} ></span></div>
           <div className='sous-contain'><span className='span1'>Donnée manquante ou valeur nulle</span> <span className='span2' style={{backgroundColor:"#B3B3B3"}} ></span></div>
          </div>
          <div className='w-100 d-flex justify-content-center align-items-center'>
            <div className='spaced-div commune'>
              <CommuneDashbaord />
            </div>
          </div>  
        </div>
        <div className="communeinfo">
          <div className='spaced-div contain-titre'>
            <div className="arrow">
              <h1 className='gros-titre'>Système d'information géographique</h1>
            </div>
          </div>

{/*
          <div className="cardrowdomain">
            <div className="card">
              <Button
                className='btnIcon'
                style={{
                  backgroundColor: isEducation ? "#316685" : "#7b9bad",
                }}
                onClick={HandleEducation}
              >
                <img
                  alt="precvadi"
                  src={educicon}
                  className="carouselicon"
                  fluid
                  rounded
                />
                <h5 className='titreES'>Education</h5>
              </Button>
            </div>
            <div className="card">
              <Button
              className='btnIcon'
                style={{
                  backgroundColor: isSante ? "#316685" : "#7b9bad",
                }}
                type="button"
                onClick={HandleSante}
              >
                <img
                  alt="precvadi"
                  src={santeicon}
                  className="carouselicon"
                  fluid
                  rounded
                />
                <h5 className='titreES'>Sante</h5>
              </Button>
              </div>

              <div className="card">
              <Button
              className='btnIcon'
                style={{
                  backgroundColor:"#289642",
                }}
                type="button"
                onClick={openModal}
              >
                <img
                  alt="precvadi"
                  src={tableicon}
                  className="carouselicon"
                  fluid
                  rounded
                />
                <h5 className='titreES'>Tableau</h5>
              </Button>
            </div>
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              contentLabel="Example Modal"
              style={{
                overlay: {
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                },
                content: {
                  position: "relative",
                  top: "55%",
                  left: "50%",
                  right: "auto",
                  bottom: "auto",
                  marginRight: "-50%",
                  transform: "translate(-50%, -50%)",
                  width: "90%",
                  padding: "10px",
                },
              }}
            >
              <span
        style={{
          fontSize: "20px",
          backgroundColor: "#e7962d29",
          color: "#e7962ddb",
          marginBottom: "5px",
          borderBottom: activeTab === 'structure' ? "2px solid #E7962D" : "none",
          cursor: "pointer",
          padding: "7px"
        }}
        onClick={() => setActiveTab('structure')}
      >
        données par structure
      </span>
      <span
        style={{
          fontSize: "20px",
          backgroundColor: "#e7962d29",
          color: "#e7962ddb",
          marginLeft: "10px",
          marginBottom: "5px",
          borderBottom: activeTab === 'communal' ? "2px solid #E7962D" : "none",
          cursor: "pointer",
          padding: "7px"
        }}
        onClick={() => setActiveTab('communal')}
      >
        données communale
      </span>

      <span
        style={{
          fontSize: "20px",
          backgroundColor: "#e7962d29",
          color: "#e7962ddb",
          marginBottom: "5px",
          marginLeft: "10px",
          borderBottom: activeTab === 's_structure' ? "2px solid #E7962D" : "none",
          cursor: "pointer",
          padding: "7px"
        }}
        onClick={() => setActiveTab('s_structure')}
      >
        santé structure
      </span>
      <span
        style={{
          fontSize: "20px",
          backgroundColor: "#e7962d29",
          color: "#e7962ddb",
          marginLeft: "10px",
          marginBottom: "5px",
          borderBottom: activeTab === 's_communal' ? "2px solid #E7962D" : "none",
          cursor: "pointer",
          padding: "7px"
        }}
        onClick={() => setActiveTab('s_communal')}
      >
        santé commune
      </span>

      
              <div>    
          {
            activeTab == "structure" ? <Itableindicateur /> :
            activeTab == "communal" ? <Itableindicateur_2 />:
            activeTab == "s_structure" ? <S_Itableindicateur /> :
            activeTab == "s_communal" && <S_Itableindicateur_2 />
          }
              </div>

              <img
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  cursor: "pointer",
                }}
                onClick={closeModal}
                alt="precvadi"
                src={fermerModal}
                title="fermer"
                fluid
                rounded
                width="30px"
                heigth="30px"
              />
            </Modal>
              
          </div>
            */}
          <div className='spaced-div tabES' style={{width:`91.5%`, marginTop: `1rem`}}>
            {isEducation && <TableauBordEducation />}
            {isSante && <TableauBordSante />}
          </div>
       {/*   <TableauBordEducation />*/}
        </div>
        {/* <Demographie/>  */}
        <div className='part-newletter'>
          <div className='spaced-div part-newletter-1'>
            <div className='letter'>
              <div  style={{width:`100%`}}>
                  <h4 className='sous-titre1'>Nos contacts</h4>
              </div>
              <div className='num'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16" className='icon'>
                  <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"/>
                </svg>
                <h4 className='contact'>(+221) 77 611 64 32</h4>
              </div>
              <div className='num'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-envelope-fill" viewBox="0 0 16 16" className='icon'>
                  <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586zm3.436-.586L16 11.801V4.697z"/>
                </svg>
                <h4 className='contact'>precvadiboulel@gmail.com</h4>
              </div>
              <div className='num'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16" className='icon'>
                  <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6"/>
                </svg>
                <h4 className='contact'>Departement de Kaffrine</h4>
              </div>
            </div>
            <div className='letter'>
              <Newsletter />
            </div>
          </div>
        </div>
        <div className='w-10 d-flex justify-content-center align-items-center'>
          <div className='spaced-div '>
            <MonCarrousel2 />
          </div>
        </div>
        <div className='part-partenaire'>
          <div className='spaced-div part-partenaire-1'>
            <div className="arrow">
                <h1 className='gros-titre'>Nos partenaires</h1>
            </div>
            {/* <div style={{width:`50%`, height:`54.5vh`, border:`1px solid`}}>
              
            </div> */}
            <div className='spaced-div partenaire'>
              
              <Partenaires />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Home;
